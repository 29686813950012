import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const SiteCreatorPage = () => (
  <div>
    <SEO 
        title="Free and Easy site creator" 
        description="A simple–to–use site creator with a choice of fully responsive charge–free themes."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Free and easy</span> site creator <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">A simple–to–use site creator with a choice of fully responsive charge–free themes.</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/installer/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/web-site-installer-banner-01.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> In the Sheephostingbay control panel you’ll discover a simple to operate site creator 
                 that you can leverage to set–up a new, eye–pleasing site yourself. It’s in fact easy and simple to learn, even if you are not 
                 well-versed in site building. The builder has plenty of site themes available in a plethora of color patterns that you could 
                 tweak up to your liking. And best of all, your website will look cool across mobile phones!
                 <br/><br/>
                 <span role="img" aria-label="emoji">✨ </span> The site creator is present with all website hosting, VPS packages, semi-dedicated 
                 hosting packages, and dedicated servers hosting packages packages which can be purchased with the Sheephostingbay Web 
                 Control Panel.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/website-builder.webp" className="w-60" alt="A convenient–to–use site creator" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A convenient–to–use site creator</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    No programming skills are necessary<br/><br/>
                                    The purpose of our site creator is to make site building intuitive and to assist you craft an amazing site 
                                    without having to type a line of HTML or PHP, or another coding languages.
                                    <br/><br/>
                                    You could do that with the help of content elements which you can add to every site theme and then modify 
                                    and relocate as you want. And last but not least, you can remove the elements you have no use for with 
                                    just a mouse click. If you choose to make your website more enticing, you could instantly embed videos 
                                    and photos, or even an e–cart to it.
                                    <br/><br/>
                                    And it all functions with no problems.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">A set of fully customizable website templates</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Cool website designs that look excellent on every type of device<br/><br/>
                                    To create an attractive site, you’ll need to create a stable infrastructure. This is exactly why, the 
                                    Sheephostingbay’s site creator offers a large set of distinctive site designs, suitable for any type 
                                    of site – personal profiles, e–shops, forums, and so on.
                                    <br/><br/>
                                    Each design template is easy–to–redesign, with numerous layouts, unique color setups and inbuilt support 
                                    for well over a hundred different fonts. You could change every one of these options with a click of 
                                    the mouse. Moreover, if at some point you have the desire to change your template and pick a new 
                                    one, all personalizations you have made will be moved over automatically.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/free-themes-07.webp" className="w-60" alt="A set of fully customizable website templates" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/video-tutorials-02.webp" className="w-60" alt="Integrated knowledge base and step–by–step video tutorials" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Integrated knowledge base and step–by–step video tutorials</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Check out just how straightforward it indeed is to kickstart a web presence<br/><br/>
                                    If you need any kind of guidance making use of the Sheephostingbay’s site creator, there are a variety of 
                                    how–to articles and video tutorials that cover the most commonly asked questions.
                                    <br/><br/>
                                    With them, you’ll be able to check out exactly how to change your existing website template, how to add 
                                    new pages to a website or perhaps how to add an e–shop.
                                    <br/><br/>
                                    In case you are unable to discover the information you need there, you can always get hold of us. We’re 
                                    online round–the–clock and we answer to all support requests in under an hour, guaranteed.
                                    </div>
                                </div>
                            </div>
                        </div>   

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default SiteCreatorPage
